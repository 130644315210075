import React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import g from "glamorous";
import Img from "gatsby-image";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import AdresseSidebar from "../components/AdresseSidebar";
import ContactSidebar from "../components/ContactForm";
import VideoBlock from "../components/VideoBlock";
import TemoignagesAccueil from "../components/TemoignagesAccueil";
import NewsBlock from "../components/NewsBlock";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaPlus } from "react-icons/fa";

export default function Homepage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { lvpa, protheses, keybodySkin, onda, keybodyLipo, mesolift } = data;

  const Wrapper = g.main({
    maxWidth: `1200px`,
    margin: `3em auto 2.5em`,
    "@media(max-width: 767px)": {
      margin: `1em auto 2.5em`,
    },
  });

  const H1 = g.h1({
    margin: `.8em 0 .1em`,
    color: `#3c4d68`,
    fontSize: `1.7em`,
    fontWeight: `500`,
    lineHeight: `1.25em`,
    letterSpacing: `0.028em`,
    "@media(max-width: 767px)": {
      fontSize: `1.3em`,
      margin: `0 0 .1em`,
    },
  });

  const H2 = g.h2({
    position: `relative`,
    fontSize: `32px`,
    fontWeight: `500`,
    marginTop: `0.2em`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `205px`,
      border: `.063em solid #3c4d68`,
      display: `inline-block`,
      width: `610px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .5em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Card = g.div({
    backgroundColor: `white`,
    marginTop: `0`,
    marginBottom: `2em`,
    borderRadius: `6px`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    transition: `all 0.15s ease-in-out`,
    ":hover": {
      border: `1px solid rgba(73,105,126,0.18)`,
      boxShadow: `0 1px 10px rgba(0,0,0,.26)`,
      transition: `all 0.15s ease-in-out`,
    },
    "@media(max-width: 767px)": {
      marginBottom: `1.5em`,
    },
  });

  const Title = g.h2({
    padding: `0 .5em 0 .82em`,
    margin: `.7em 0 .3em`,
    font: `700 18px/1.35em -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
  });

  const Text = g.p({
    padding: `0 .5em 0 .8em`,
    marginBottom: `.6em`,
    color: `#1f1f1f`,
  });

  const PlusWrapper = g.div({
    display: `flex`,
    justifyContent: `flex-end`,
    color: `#3c4d68`,
    margin: `0 1.5em .85em`,
    fontSize: `.8em`,
  });

  return (
    <g.Div margin={`0 auto`} backgroundColor={`#fcfcfc`}>
      <Helmet>
        <title>Page non trouvée - Erreur 404 | Dr Cédric Kron</title>
        <meta
          name="description"
          content="La page demandée n'est pas disponible. Veuillez consulter une autre page du site."
        />
        <html lang="fr" />
        <link
          rel="alternate"
          hreflang="en"
          href="https://www.dr-kron.com/en/404/"
        />
        <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Page non trouvée - Erreur 404,
          "item": "https://www.dr-kron.com/404/"
          }]
              }
    `}</script>
      </Helmet>
      <Menu pathEn="/en/" />
      <Wrapper>
        <Container
          css={{
            paddingRight: `12px !important`,
            paddingLeft: `12px !important`,
          }}
        >
          <Row>
            <Col>
              <p className="intro" css={{ marginBottom: `2em` }}>
                <H1>Page indisponible</H1>
                La page demandée n'est pas disponible. Veuillez consulter une
                autre page du site.
              </p>
            </Col>
          </Row>

          <Row>
            <Col
              span={{ sm: 12 / 12, lg: 74 / 100 }}
              css={{ backgroundcolor: `white` }}
            >
              <section>
                <H2>Interventions</H2>
                <Row>
                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    {" "}
                    <Card>
                      <Link to="/visage/lifting-visage-paris/">
                        <Img
                          fluid={lvpa.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>Lifting du visage LVPA</Title>
                        <Text>
                          Le lifting du visage LVPA permet de corriger
                          naturellement et durablement les relâchements du
                          visage, avec des cicatrices invisibles et une
                          resocialisation en moins d'une semaine.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>

                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    <Card>
                      <Link to="/seins/protheses-mammaires/">
                        <Img
                          fluid={protheses.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>Prothèses mammaires</Title>
                        <Text>
                          Une augmentation mammaire par prothèses doit embellir
                          la forme et la taille d'une poitrine tout en proposant
                          un résultat naturel, conforme au désir de la patiente
                          et à son idée de la féminité.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>

                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    <Card>
                      <Link to="/peau/keybody-skin/">
                        <Img
                          fluid={keybodySkin.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>Keybody-Skin</Title>
                        <Text>
                          Un protocole de régénération en profondeur de la peau
                          qui met à profit les dernières avancées en médecine
                          esthétique : laser fractionné sub-ablatif, principes
                          actifs de cosméceutique, lumière photonique et
                          préparations cosmétologiques.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>
                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    <Card>
                      <Link to="/silhouette/onda-coolwaves/">
                        <Img
                          fluid={onda.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>ONDA Coolwaves</Title>
                        <Text>
                        Fondé sur l’action ciblée de micro-ondes
                        électromagnétiques, le traitement ONDA élimine la
                        cellulite, réduit les amas de graisses localisés et
                        raffermit la peau en toute sécurité, d’une manière
                        définitive, efficace et non invasive.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>
                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    <Card>
                      <Link to="/silhouette/keybody-lipo/">
                        <Img
                          fluid={keybodyLipo.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>Keybody-Lipo</Title>
                        <Text>
                          Protocole non invasif et innovant de suppression
                          définitive des graisses et de sculpting musculaire,
                          Keybody-Lipo permet d'obtenir un résultat comparable à
                          une lipoaspiration sans les effets secondaires.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>
                  <Col span={{ sm: 12 / 12, md: 1 / 2 }}>
                    <Card>
                      <Link to="/visage/mesolift-nctf/">
                        <Img
                          fluid={mesolift.childImageSharp.fluid}
                          alt=""
                          css={{
                            marginBottom: `.5em`,
                            borderRadius: `6px 6px 0 0`,
                          }}
                        />
                        <Title>Mésolift</Title>
                        <Text>
                          Un traitement de mésothérapie qui revitalise en
                          profondeur la peau du visage, en lui apportant tous
                          les nutriments dont elle a besoin. Un mésolift corrige
                          les effets du vieillissement, améliore la texture, la
                          densité et l'hydratation de la peau.
                        </Text>
                        <PlusWrapper>
                          <FaPlus
                            css={{
                              fontSize: `1em`,
                              padding: `0.45em .3em 0em`,
                            }}
                          />
                          En savoir plus
                        </PlusWrapper>
                      </Link>
                    </Card>
                  </Col>
                  <Col>
                    <PlusWrapper
                      css={{
                        fontSize: `1.6em`,
                        margin: `0 0 1.4em 0`,
                        "@media(max-width: 767px)": {
                          fontSize: `1.35em`,
                          marginTop: `-0.3em`,
                        },
                      }}
                    >
                      <Link
                        to="/interventions/"
                        css={{ textureDecoration: `none`, color: `#3c4d68` }}
                      >
                        <FaPlus
                          css={{ fontSize: `1em`, padding: `0.1em .35em 0em` }}
                        />
                        Voir toutes les interventions
                      </Link>
                    </PlusWrapper>
                  </Col>
                </Row>
              </section>
            </Col>
            <Col span={{ sm: 12 / 12, lg: 26 / 100 }}>
              <aside>
                <NewsBlock />
                <ContactSidebar about="la chirurgie esthétique" />
                <AdresseSidebar />
                <VideoBlock />
              </aside>
            </Col>
          </Row>

          <TemoignagesAccueil />
        </Container>
      </Wrapper>
      <Footer />
    </g.Div>
  );
}

export const pageQuery = graphql`
  query ErrorQuery {
    lvpa: file(relativePath: { eq: "images/lifting-visage-lvpa.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    protheses: file(
      relativePath: { eq: "images/augmentation-mammaire-protheses.jpg" }
    ) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodySkin: file(relativePath: { eq: "images/keybody-skin.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    onda: file(
      relativePath: { eq: "images/onda-coolwaves.jpg" }
    ) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 388) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybody: file(relativePath: { eq: "images/keybody.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodyLipo: file(
      relativePath: { eq: "images/keybody-lipo-supprimer-graisses.jpg" }
    ) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    mesolift: file(relativePath: { eq: "images/mesolift.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
